/* MENU OPEN AND CLOSE */
var hamburger = $(".hamburger");
$(hamburger).click(function(e){
    e.preventDefault();

	$(".hamburger").toggleClass("active");

	$(".hamburger .first").toggleClass("active");
	$(".hamburger .second").toggleClass("active");
	$(".hamburger .third").toggleClass("active");
});

$(".nav-container").on('hidden.bs.collapse', function () {
  $("body").removeClass("menu-active");
});
$(".nav-container").on('show.bs.collapse', function () {
  	$("body").addClass("menu-active");
});