window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');

} catch (e) {}

//require('bootstrap-transition');
//require('bootstrap-collapse');
//require('bootstrap-scrollspy');

//require('./jquery/jquery.magnific-popup.min');
require('bootstrap');

var gHamburger = require('./plugins/google-hamburger');
var bootstrapDropdown = require('./plugins/bootstrap-dropdown');
require('./vendor/salvattore.min.js');

// Trigger Search bar
$('.search-button').on('click', function(){

	var searchBar = $('.search-area');
	searchBar.addClass("active");

	$(".search-input").focus();

	// Add backdrop
	$('body').append($('<div>', {class: 'modal-backdrop fade show'}));

	searchBar.find('.close').on('click', function(){
		searchBar.removeClass("active");
		$('.modal-backdrop').remove();
		return false;
	});
	return false;
});

// Plus To Minus in FAQ / Accordian
$('.card-header .btn').on('click', function(){

  	$(".card-header .btn").not(this).removeClass('active');
  	$(".card-header .btn").not(this).closest(".card-header").find(".plus-to-minus").removeClass('active');

  	$(this).toggleClass('active');
  	$(this).closest(".card-header").find(".plus-to-minus").toggleClass('active');
});

// Open targeted links in new window
/*
function openInNewWindow(href, parentClass = '') {
	$(parentClass+' a[href="'+href+'"]').click(function(){
		window.open(this.href);
		return false;
	});
}
openInNewWindow("/innovation", ".footer-menu-section");
*/

//require('./libs/jquery.sticky');
//$(".navbar-submenu").sticky({topSpacing:0, zIndex:99});

//require('./libs/jquery.instashow.packaged');

// Slider
require('slick-carousel');
//$('.slider').slick();