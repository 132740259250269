var parentTimer;
var parentDelay = 200;
var childTimer;
var childDelay = 200;

// Iphone Work-around - Go to link on click
$("nav.navbar .navbar-nav > .dropdown a").on("click touchend", function(e) {
    var el = $(this);
    var link = el.attr("href");
    window.location = link;
  });

$('nav.navbar .navbar-nav .dropdown-menu').hide();
$('nav.navbar .navbar-nav > .dropdown').hover(
	function () {
		//$(this).siblings().removeClass('addArrow').children('ul').stop(true, true).slideUp(200, 'swing');
		$("nav.navbar .navbar-nav > .dropdown").not($(this)).removeClass('addArrow').children('ul').stop(true, true).slideUp(200, 'swing');
        $(this).addClass('addArrow').children('ul').stop(true, true).slideDown(200, 'swing');
        clearTimeout(parentTimer);
	},
	function () {
		var $this = $(this);
		parentTimer = setTimeout(function() {
			$this.removeClass('addArrow').children('ul').stop(true, true).slideUp(200, 'swing');
		}, parentDelay);
	}
);
$('nav.navbar .navbar-nav > .dropdown > .dropdown-menu > .dropdown').hover(
	function () {
		$(this).siblings().children('ul').slideUp(50, 'swing');
		$(this).children('ul').stop(true, true).slideDown(200, 'swing');
		clearTimeout(childTimer);
	},
	function () {
		var $this = $(this);
		childTimer = setTimeout(function() {
			$this.children('ul').stop(true, true).slideUp(200, 'swing');
		}, childDelay);

		clearTimeout(parentTimer);
	}
);